<template>
  <div class="ManagerAllPaiements">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow>
      <CCol>
        <h1> Paiements </h1>
      </CCol>
    </CRow>


    <CCard class="mt-3">
      <CCardBody>
        <CRow>
          <CCol xl="5">
            <CInput
              label="Date de début"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="startDateFilter">
            </CInput>
          </CCol>
          <CCol xl="5">
            <CInput
              label="Date de fin"
              type="date"
              :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
              v-model="endDateFilter">
            </CInput>
          </CCol>
          <CCol xl="2">
            <CButton
              color="primary"
              variant="outline"
              shape="pill"
              block
              @click="getAllPaiementsForManager">
            Filtrer par date
          </CButton>
          </CCol>
        </CRow>

        <CRow class="mt-3 mt-xl-0">
          <CCol>
            <a class="eclerk-link" href="#" @click="reinitializeWeek">
              Depuis le début de la semaine
            </a>
            <br class="d-sm-block d-md-none">

            <a class="ml-0 ml-md-2 eclerk-link" href="#" @click="reinitializeMonth">
              Depuis le début du mois
            </a>
          </CCol>
        </CRow>

        <CRow class="mt-3">

          <CCol sm="4">
            <CInputCheckbox
              label="Tous les clients"
              :checked.sync="isAllClientsFilter"
              :custom="true"
            >
            </CInputCheckbox>
          </CCol>
          <CCol v-if="!isAllClientsFilter" sm="8" class="mt-2 mt-sm-0">
            <vue-bootstrap-typeahead
              v-model="clientFilterName"
              :data="filterClients"
              :serializer="item => item.name"
              @hit="clientFilterObject = $event"
               placeholder="Chercher votre client par nom"
            />

          </CCol>
        </CRow>

      </CCardBody>
    </CCard>

    <CRow style="font-size: 1.2rem;">
      <CCol>
        <strong class="text-primary" style="font-size: 1.6rem;">{{nbPaiements}}</strong> paiements réalisés
        pour un montant de <strong class="text-success" style="font-size: 1.6rem;">
          {{formatThousandSeparatorNumber(parseFloat(montantTotal).toFixed(2))}}
        </strong> €
      </CCol>
    </CRow>

    <CRow>
      <CCol>
        <CCard >
          <CCardBody>
            <CRow>
              <CCol>
                <CDataTable
                  class="cursor-table"
                  :items-per-page="50"
                  pagination
                  sorter
                  :items="paiementsRender"
                  :fields="paiementsRenderFields"
                  @row-clicked="goToFacturePage"
                  :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
                >
                <template #over-table>
                  <exportTableDataComponent
                    v-bind:data="paiementsRender"
                    v-bind:fields="paiementsFileRenderFields"
                    name="Eclerk - Paiements"
                    label="Exporter la liste"
                  />
                </template>
                <template #date="{item}">
                  <td class="text-center">
                   <nobr>{{$dayjs(item.date).format('DD/MM/YYYY')}}</nobr>
                   <span v-if="item.online_paiement"><br>
                     <small class="text-info">En ligne via Eclerk</small>
                   </span>
                  </td>
                </template>
                <template #client="{item}">
                  <td>
                   <nobr>{{item.client}}</nobr>
                  </td>
                </template>
                <template #facture_number="{item}">
                  <td class="text-center">
                   <nobr>{{item.facture_number}}</nobr>
                  </td>
                </template>
                <template #montant_ttc="{item}">
                  <td class="text-center">
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.montant_ttc).toFixed(2))}} €</nobr>
                  </td>
                </template>

                <template #modalites="{item}">
                  <td class="text-center">
                    {{modalitesPaiementRender(item.modalites)}}
                  </td>
                </template>

                <template #reste_a_payer="{item}">
                  <td class="text-center">
                    <nobr>{{formatThousandSeparatorNumber(parseFloat(item.reste_a_payer).toFixed(2))}} €</nobr>
                    <span v-if="parseFloat(item.reste_a_payer).toFixed(0) == 0"><CIcon class="text-success ml-2" name="cil-check-circle" /></span>
                    <span v-else><CIcon class="text-danger ml-2" name="cil-x-circle" /></span>
                  </td>
                </template>

                <template #no-items-view>
                  <CRow>
                    <CCol class="text-center">
                      Aucun paiement
                    </CCol>
                  </CRow>
                </template>
                </CDataTable>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { APIManagerConnected } from '@/api/APIManagerConnected'
import { APIUserConnected } from '@/api/APIUserConnected'
import exportTableDataComponent from '@/components/exportTableDataComponent'
import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()

export default {
  name: 'ManagerAllPaiements',
  components: {
    Loading,
    VueBootstrapTypeahead,
    exportTableDataComponent
  },
  mixins: [
    renderMixins,
    userMixins
  ],
  data: function () {
    var today = this.$dayjs()
    var oneMonthAgo = today.subtract('3', 'months')
    return {

      // ------------- PAIEMENTS -----------
      startDateFilter: oneMonthAgo.format('YYYY-MM-DD'),
      endDateFilter: today.format('YYYY-MM-DD'),

      isAllClientsFilter: true,
      filterClients: [],
      clientFilterName: '',
      clientFilterObject: {
        id: '',
        name: ''
      },

      paiements: [],
      paiementsRender: [],
      paiementsRenderFields: [
        { key: "date", label: "Date du paiement" },
        { key: "client", label: "Client"},
        { key: "facture_number", label: "N° de la facture"},
        { key: "intitule", label: "Intitulé de la facture", sorter: false},
        { key: "montant_ttc", label: "Montant", sortable: false },
        { key: "modalites", label: "Modalités" },
        { key: "reste_a_payer", label: "Reste à payer sur la facture" },
      ],
      paiementsFileRenderFields: {
        'Date de paiement': 'date',
        'Client': 'client',
        'Numéro de facture': 'facture_number',
        'Libellé de la facture': 'intitule',
        'Montant': "montant_ttc",
        "Modalités de paiement": 'modalites',
      },
      isAllManagerPaiementsDashboardLoading: false,

      nbPaiements: 0,
      montantTotal: 0,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isAllManagerPaiementsDashboardLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAllPaiementsForManager()
  },


  watch: {

    clientFilterName(newQuery) {
      if (newQuery == '') {
        this.filterClients = []
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.filterClients = res.data
        })
      }
    },

    clientFilterObject(newObject) {
      if (newObject.id === parseInt(newObject.id, 10)) {
        if (newObject.id >= 1) {
          this.getAllPaiementsForManager()
        }
      }
      else if (newObject.id == '') {
        this.getAllPaiementsForManager()
      }
    },

    isAllClientsFilter(newBoolean) {
      if (newBoolean) {
        this.clientFilterObject = {
          id: '',
          name: ''
        }
      }
    },

    paiements: function (newPaiements) {

      // PAIEMENT STATISTIQUES
      this.nbPaiements = 0
      this.montantTotal = 0

      // PAIEMENT DETAILLED
      this.paiementsRender = []

      for (var i = 0; i < newPaiements.length; i++) {
        var temp_data = {
          'client': newPaiements[i].facture.client_name,
          'date': newPaiements[i].date,
          'id': newPaiements[i].id,
          'intitule': newPaiements[i].facture.facture_title,
          'facture_id': newPaiements[i].facture.id,
          'facture_number': newPaiements[i].facture.facture_number,
          'modalites': newPaiements[i].modalites,
          'montant_ttc': newPaiements[i].montant_ttc,
          'online_paiement': newPaiements[i].online_paiement,
          'reste_a_payer': parseFloat(newPaiements[i].facture.montant_total_ttc) - parseFloat(newPaiements[i].facture.montant_total_paid),
        }

        this.montantTotal = parseFloat(this.montantTotal) + parseFloat(newPaiements[i].montant_ttc)
        this.nbPaiements ++

        this.paiementsRender.push(temp_data)
      }
    },
  },
  methods: {
    // -------------- GETTERS -----------------------
    getAllPaiementsForManager () {
      this.isAllManagerPaiementsDashboardLoading = true
      apiManagerConnected.getAllPaiementsForManager(this.token, this.startDateFilter, this.endDateFilter, this.clientFilterObject.id)
      .then((result) => {
        this.paiements = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllManagerPaiementsDashboardLoading = false
      })
    },

    // ------------ Filter functions ----------------
    reinitializeWeek () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('week').format('YYYY-MM-DD')
      this.getAllPaiementsForManager()
    },
    reinitializeMonth () {
      var today = this.$dayjs()
      this.endDateFilter = today.format('YYYY-MM-DD')
      this.startDateFilter = today.startOf('month').format('YYYY-MM-DD')
      this.getAllPaiementsForManager()
    },

    // ------------ ROUTER FUNCTIONS -------------------
    goToFacturePage(item) {
      var routeData = this.$router.resolve('modify-facture/' + item.facture_id);
      window.open(routeData.href, '_blank');
    }

  }
}
</script>

<style>
.no-border-table td {
  border-top: None;
}

.thead-clickable {
  cursor: pointer;
}

.thead-clickable:hover{
  color: black !important;
}

.toast {
  flex-basis: auto !important;
}
.custom-toast {
  background-color: white;
}


</style>
